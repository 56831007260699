/**
 * Get the default ApexCharts configuration object
 *
 * @returns {Object}
 */
export default function baseOptions() {
  return {
    "colors": [
      "#f05d56",
      "#FFC107",
      "#1890FF",
      "#FF4842",
      "#54D62C"
    ],
    "chart": {
      "foreColor": "#919EAB",
      "fontFamily": "Public Sans, sans-serif",
      toolbar: {
        show: false
      }
    },
    "states": {
      "hover": {
        "filter": {
          "type": "lighten",
          "value": 0.04
        }
      },
      "active": {
        "filter": {
          "type": "darken",
          "value": 0.88
        }
      }
    },
    "fill": {
      "opacity": 1,
      "gradient": {
        "type": "vertical",
        "shadeIntensity": 0,
        "opacityFrom": 0.4,
        "opacityTo": 0,
        "stops": [
          0,
          100
        ]
      }
    },
    "dataLabels": {
      "enabled": false
    },
    "stroke": {
      "width": 3,
      "curve": "smooth",
      "lineCap": "round"
    },
    "grid": {
      "strokeDashArray": 3,
      "borderColor": "rgba(145, 158, 171, 0.24)"
    },
    "xaxis": {
      "axisBorder": {
        "show": false
      },
      "axisTicks": {
        "show": false
      },
      "labels": {
        "style": {
          "colors": "#546e7a"
        }
      },
      "title": {
        "style": {
          "color": "#546e7a"
        }
      }
    },
    "yaxis": {
      "labels": {
        "style": {
          "colors": "#546e7a"
        }
      },
      "title": {
        "style": {
          "color": "#546e7a"
        }
      }
    },
    "markers": {
      "size": 0,
      "strokeColors": "#fff"
    },
    "tooltip": {
      "x": {
        "show": false
      },

      theme: host.theme.type || "light",
    },
    "legend": {
      "show": true,
      "fontSize": "13",
      "position": "top",
      "horizontalAlign": "right",
      "markers": {
        "radius": 12
      },
      "itemMargin": {
        "horizontal": 12
      },
      "labels": {
        "colors": "#212B36"
      }
    },
    "plotOptions": {
      "pie": {
        "donut": {
          "labels": {
            "show": true
          }
        }
      }
    }
  }
}